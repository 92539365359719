#offerproduct---custom {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  visibility: visible;
  overflow-y: scroll !important;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.3);
}

.show-sm-only {
  display: none;
  width: 100%;
  text-align: right;
  color: #fa9324;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 800px) {
  #offerproduct---custom .popup--content--offerproduct {
    width: 90%;
    margin: 20px auto !important;
  }
  #offerproduct---custom h1 {
    font-size: 20px;
  }
  .show-sm-only {
    display: flex;
  }
}

@media (max-width: 400px) {
  #offerproduct---custom .popup--content--offerproduct {
    width: 100%;
    margin: 0px 0px !important;
  }
}

#offerproduct---custom .popup--content--offerproduct {
  max-width: 800px;
  margin: 50px auto;
  position: relative;
  box-sizing: border-box;
  padding: 30px;
  background-color: #fff;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

#offerproduct---custom #offerproduct--close {
  background: transparent;
  border: 0px;
  font-size: 40px;
  line-height: 40px;
  padding: 10px 10px;
  float: right;
}

#offerproduct---custom .div--flex-1,
#offerproduct---custom .div--flex-2,
#offerproduct---custom .div--flex-3 {
  display: flex;
  flex-wrap: wrap;
}

#offerproduct---custom .div--flex-3 .offerd_product {
  flex: 0 0 33.33333333333333%;
  width: 33.33333333333333%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
#offerproduct---custom .div--flex-2 .offerd_product {
  flex: 0 0 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

#offerproduct---custom .div--flex-1 .offerd_product {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

#offerproduct---custom .div--flex-1 .offerd_product .bsub-widget,
#offerproduct---custom .div--flex-2 .offerd_product .bsub-widget,
#offerproduct---custom .div--flex-3 .offerd_product .bsub-widget {
  display: none;
}
@media (max-width: 800px) {
  #offerproduct---custom .div--flex-1,
  #offerproduct---custom .div--flex-2,
  #offerproduct---custom .div--flex-3 {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  #offerproduct---custom .div--flex-1 .offerd_product,
  #offerproduct---custom .div--flex-2 .offerd_product,
  #offerproduct---custom .div--flex-3 .offerd_product {
    flex: 0 0 100%;
    width: 100%;
  }
}

#offerproduct---custom .div--flex-1 .offerd_product p,
#offerproduct---custom
  .div--flex-2
  .offerd_product
  p
  #offerproduct---custom
  .div--flex-3
  .offerd_product
  p {
  text-align: center;
}

@font-face {
  font-family: "cera_prothin";
  src: url("https://cdn.shopify.com/s/files/1/2653/8406/files/cera_pro_thin-webfont.woff2")
      format("woff2"),
    url("https://cdn.shopify.com/s/files/1/2653/8406/files/cera_pro_thin-webfont.woff")
      format("woff");
  font-weight: 100;
}

/** AHMED CSS START **/

@font-face {
  font-family: "cera_prolight";
  src: url("https://cdn.shopify.com/s/files/1/2653/8406/files/cera_pro_light-webfont.woff2")
      format("woff2"),
    url("https://cdn.shopify.com/s/files/1/2653/8406/files/cera_pro_light-webfont.woff")
      format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "cera_proregular";
  src: url("https://cdn.shopify.com/s/files/1/2653/8406/files/cera_pro_regular-webfont.woff2")
      format("woff2"),
    url("https://cdn.shopify.com/s/files/1/2653/8406/files/cera_pro_regular-webfont.woff")
      format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "cera_promedium";
  src: url("https://cdn.shopify.com/s/files/1/2653/8406/files/cera_pro_medium-webfont.woff2")
      format("woff2"),
    url("https://cdn.shopify.com/s/files/1/2653/8406/files/cera_pro_medium-webfont.woff")
      format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "cera_probold";
  src: url("https://cdn.shopify.com/s/files/1/2653/8406/files/cera_pro_bold-webfont.woff2")
      format("woff2"),
    url("https://cdn.shopify.com/s/files/1/2653/8406/files/cera_pro_bold-webfont.woff")
      format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "cera_problack";
  src: url("https://cdn.shopify.com/s/files/1/2653/8406/files/cera_pro_black-webfont.woff2")
      format("woff2"),
    url("https://cdn.shopify.com/s/files/1/2653/8406/files/cera_pro_black-webfont.woff")
      format("woff");
  font-weight: 800;
}

.desktop-image {
  display: block;
}

.mobile-image {
  display: none;
}

@media screen and (max-width: 768px) {
  .desktop-image {
    display: none;
  }

  .mobile-image {
    display: block;
    margin: 0px auto;
  }
}

.product_single_detail_section .product__title,
.product_single_detail_section div#ProductPrice {
  font-family: "cera_probold";
}

.product_single_detail_section .product__title {
  font-size: 32px;
  color: #685f52;
  line-height: 1em;
}

.product_single_detail_section div#ProductPrice {
  color: #685f52;
  text-align: center;
}

.product_single_detail_section div#ProductPrice span {
  margin-left: 0px;
}

.product_single_detail_section
  .yotpo.bottomLine
  .yotpo-bottomline
  .rating-star {
  font-size: 22px;
  margin-right: 2px;
  color: #eba870;
}

.product_single_detail_section .yotpo .text-m {
  margin-top: 3px;
  margin-left: 3px;
  color: #eba870 !important;
  font-size: 14px;
}

.product_single_detail_section .swatch .swatch-element label {
  border-radius: 5px;
  border-width: 2px;
  font-size: 15px;
}

.product_single_detail_section .selector-wrapper-secton .swatch input:checked + label
{
  border:none!important;
  box-shadow: 0px 0px 0px 2px #00a896;
}

.product_single_detail_section .selector-wrapper-secton .swatch input + label {
  color: #685f52;
  border:none!important;
  box-shadow: 0px 0px 0px 1px #d9d9d9;
  margin-bottom:0px;
}

.product_single_detail_section .selector-wrapper-secton .swatch .swatch-element
{
  padding: 0px 10px 10px 0px;
  margin: 0px;
  margin-right: 0px!important;
}

.product-photo-container .product-photo-stage,
.product-photo-container .owl-item li.product-photo-li {
  box-shadow: inset 0 0 50px 5px rgba(240, 240, 242, 1);
  border-radius: 10px;
}

.product-photo-container .product-photo-stage img {
  mix-blend-mode: multiply;
}

.product-single .product-img-box {
  z-index: 1;
}

.product-img-box .product-photo-container {
  display: block !important;
  overflow:initial;
}

.product-img-box .product-photo-container img#product-featured-image {
  height: auto !important;
}

.product-photo-container .owl-item {
  aspect-ratio: 3 / 2;
  /* Set the aspect ratio to 150x100 (width / height) */
  position: relative;
}

.product-photo-container .owl-item img {
  mix-blend-mode: multiply;
}

.product-img-box .product-photo-thumbs.owl-theme li,
.product-img-box .product-photo-thumbs.owl-theme li a,
.product-img-box .product-photo-thumbs.owl-theme li a img {
  height: 100% !important;
}

.template-product .atc__wrapper .atc_action .atc_action_qtc {
  background-color: #f0f0f2;
  border: none;
  border-radius: 5px;
  flex: 0 0 120px;
}

.template-product .atc__wrapper .atc_action .atc_action_qtc input {
  background-color: transparent;
  font-size: 22px;
  color: #685f52;
  width: 55%;
}

.template-product .atc__wrapper .atc_action .atc_action_qtc button {
  color: #00a896;
  font-size: 22px;
  padding: 0px 6px;
}

.product_single_detail_section #AddToCart {
  border-radius: 5px;
  font-size: 18px;
  padding: 0px !important;
  height: 56px;
  text-transform: uppercase;
  font-family: "cera_probold";
}

.template-product .product__description__section {
  padding-top: 65px;
  margin-top: 0px;
  display:block;
}

.template-product .product__description__section .tab__link {
  height: 60px;
}

.template-product .product__description__section .tab__link li a {
  height: 60px;
  display: block;
  line-height: 60px;
  margin-right: 50px;
  font-size: 18px;
  font-family: "cera_probold";
  line-height: normal;
  display: flex;
  align-items: center;
}

.template-product .product__description__section .tab__link li.active a {
  border-bottom: 4px solid #00a896;
  color: #00a896;
}

.template-product .product__description__section .tab__link {
  border-bottom: 4px solid #f0f0f2;
}

.template-product .product__description__section .tab--content li.tab--item {
  padding-top: 40px !important;
}

.dt-sc-tabs-frame-content,
.dt-sc-tabs-content {
  border: none;
  padding: 0px;
  margin-bottom: 70px;
}

/** 12/26/2023 **/

.products .product-container {
  float: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.products .product-container .product-additional img {
  width: auto;
  height: auto;
  transition: none!important;
}

.products.product-container .reveal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.product-container .reveal .hidden-feature_img,
.product-container:hover .reveal .hidden-feature_img {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translateY(-50%);
  top: 50% !important;
  background-color: white;

  transition: none!important;
}

.related-products-container .nav_featured a.prev {
  left: 20px;
}

.related-products-container .nav_featured a.next {
  right: 20px;
}

.related-products-container ul .owl-item li .products {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.products .product-detail {
  float: none;
  height: initial;
  padding-top: 20px;
}

.product-detail .grid-link__title {
  font-family: cera_probold;
  line-height: normal;
  font-size: 18px;
  letter-spacing: initial;
}

.products .product-detail div#ProductPrice,
.products .product-detail .grid-link__sale_price {
  font-family: cera_probold;
  font-size: 18px !important;
  line-height: normal !important;
}

.product-container .badge.badge--sale .badge__text {
  color: white;
}

.related-products-container h2.section-header__title {
  font-size: 30px;
}

.related-products-container {
  padding: 80px 0px;
  padding-bottom:0px;
}

.more-view-wrapper-owlslider {
  margin-top: 20px;
}

.product-photo-thumbs {
  margin: 0px;
}

.more-view-wrapper-owlslider .single-page-owl-carousel a.next,
.more-view-wrapper-owlslider .single-page-owl-carousel a.prev
{
    background-color:#00a896;
    width:20px;
    height:20px;
    border-radius:20px;
    font-size:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fff;
}

.more-view-wrapper-owlslider .single-page-owl-carousel a.next
{
    right:-8px;
}

.more-view-wrapper-owlslider .single-page-owl-carousel a.prev
{
    left:-8px;
}

@media (max-width: 768px) {
  .single-product-wrap.container,
  .featured-post-section .container {
    padding: 0px 20px;
  }

  .template-product .product__description__section .tab__link li a
  {
      margin-right: 30px;
      font-size: 16px;
  }

  .product_single_detail_section .product__title
  {
    font-size:24px;
  }
}

/* Custom Bootstrap Classes */

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container
{
  max-width:1170px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

@media (max-width: 576px) {
  .col-md-4,
  .col-md-6,
  .col-md-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-md-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/** AJAX MODAL CSS**/

.ajax-success-modal .ajax-left
    {
        display:none;
    }
    
    .ajax-success-modal .ajax-right
    {
        width:100%;
    }
    
    .ajax-success-modal .content, .ajax-error-modal .modal-inner,
    .ajax-success-modal .added-to-cart.info
    {
        border:none;
        text-align:center;
        font-family: "cera_probold";
        text-decoration:none;
    }
    
    .ajax-success-modal .added-to-cart.info
    {
        padding-bottom:0px;
        margin-bottom:10px;
    }
    
    .ajax-success-modal .content, .ajax-error-modal .modal-inner
    {
        padding:30px;
    }
    
    .ajax-success-modal .success-message
    {
        margin-bottom:0px;
        padding-bottom:0px;
    }
    
    .ajax-success-modal .close-modal
    {
        right:-10px;
        top:-10px;
        background-color:#cc071c;
        width: 22px;
        height: 22px;
    
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

.video-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.video-container video {
  width: 50%;
  padding:10px;
}

.video-container video:last-child {
  margin-right: 0;
}

.little-reels h3
{
font-family: "cera_probold";
margin-bottom: 20px;
text-align:center;
font-size: 32px;
}

@media (max-width: 768px) {

  .video-container {
    flex-direction: column;
  }

  .video-container video
  {
    width:100%;
  }
  
}

#carousel_banner .featured-content
{
  width: 80%;
  line-height: normal;
}

/** HEADER 2024 **/

.menu_bar_right .icon-handbag:before,
.menu_bar_right .icon-user:before,
.mobile--menu .icon-handbag:before,
.mobile--menu .icon-user:before
{
    display:none;
}

.menu_bar_right .icon-handbag svg,
.menu_bar_right .icon-user svg,
.mobile--menu .icon-handbag svg,
.mobile--menu .icon-user svg
{
    display:block;
    height:26px;
    fill:white;
}

.mobile--menu .icon-handbag svg,
.mobile--menu .icon-user svg
{
    fill:#00a896;
}

.site-header .customer_account
{
    margin-top:3px;
}

.menu_bar_right a:hover .icon-handbag svg,
.menu_bar_right a:hover .icon-user svg
{
    fill:#17ffe4;
}

.header-type-10 #cartCount
{
    top:initial;
    bottom:-5px;
    right:-5px;
    left:initial;
    font-size:14px;
    background-color:#ffe491!important;
}

.site-nav > li > a
{
    font-family: cera_probold;
    padding:20px 16px
}

.site-nav > li.awards > a
{
    position:relative;
}

.site-nav > li.awards > a:before
{
    content: url('https://cdn.shopify.com/s/files/1/2653/8406/files/awards_icon_d6cf18d2-8207-4079-a70d-ff3753280e19.svg?v=1716126810');
    height:14px;
    display:block;
    width:20px;
    position:absolute;
    left:50%;

    left: 50%;
    transform: translateX(-50%);
    top:-0px;
}

.header-type-10 .site-nav
{
    display:flex;
    max-width:1170px;
    margin:0px auto;
    float:none;
    position:relative;
    height: 115px;
}

.header-type-10 .site-nav li:first-child
{
    position:absolute;
    left:0px;
}

.header-type-10 .sticky-wrapper.is-sticky .site-nav
{
    height:80px;
}

.grid__item
{
    min-height:initial!important;
}

.sticky-wrapper.is-sticky img
{
    width:70px!important;
}

/** FOOTER 2024 **/

.footer-icons .payment-icons li img
{
    height:29px;
}

.awards-cert-section
{
    text-align:center;
    margin:70px auto;
    margin-bottom:40px;
}

.awards-cert-list
{
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.site-footer .copyright .container
{
    display:flex;
    justify-content: space-between;
}

.site-footer h4
{
    font-family:cera_probold;
    font-size:18px;
}

.site-footer
{
    font-size:14px;
    font-family:cera_proregular;
}

.awards-cert-section h4
{
    font-size:22px;
}

.footer-model-3 .copyright
{
    padding:0px;
}

.footer-model-3 .copyright .container
{
    border-top:1px solid #dbddde;
    padding-top:40px;
}

@media (max-width: 768px)
{
    .awards-cert-section
    {
        margin-top:0px;
        margin-bottom:0px;
    }

    .awards-cert-list
    {
        flex-wrap: wrap;
    }

    .awards-cert-list li
    {
        flex: 0 1 25%;
    }

    .awards-cert-list img
    {
        max-width:70%;
        max-height:50px;
    }

    .site-footer .copyright .container
    {
        display:flex;
        flex-direction: column-reverse;
    }

    .footer-icons .payment-icons li img
    {
        height:25px;
        width:initial;
    }

    .footer-icons .payment-icons li
    {
        margin-right:0px;
    }
}
.newsletter-section{display:none !important}